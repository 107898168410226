<template>
  <v-layout class="mb-4 pb-4" wrap align-start justify-center row fill-height>
    <v-flex xs12>
      <Title title="Nueva pagina" subtitle=""></Title>
    </v-flex>
    <v-flex xs12>
      <v-card>
        <v-card-text>
          <v-container grid-list-lg fluid>
            <v-layout row wrap>
              <v-flex xs12 sm12 md12>
                <v-text-field v-model="url" label="URL (debe ser unico)" hide-details></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-text-field v-model="name" label="Titulo" hide-details></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md12>
                <QuillEditorFormat v-model="description" :reference="'quill-editor-format'" />
              </v-flex>
              <v-flex xs12 sm6 md12>
                <v-subheader>
                  Seo
                </v-subheader>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-text-field v-model="seoTitle" label="Title" hide-details></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-textarea v-model="seoDescription" label="Description"></v-textarea>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-textarea v-model="seoKeywords" label="Keywords"></v-textarea>
              </v-flex>
              <v-radio-group v-model="active" class="pa-0 ma-0" :mandatory="false">
                <v-radio color="secondary" label="Inactivo" :value="false"></v-radio>
                <v-radio color="secondary" label="Activo" :value="true"></v-radio>
              </v-radio-group>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" :loading="loading" :disabled="loading" flat @click="save">
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { CONFIG } from '../../config'
import Title from '../useful/title.vue'
import QuillEditorFormat from '../useful/quillEditorFormat.vue'

export default {
  name: 'CMSId',
  components: {
    Title,
    QuillEditorFormat
  },
  props: ['id'],
  data() {
    return {
      url: '',
      name: '',
      description: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      active: false,
      loading: false
    }
  },
  mounted() {
    if (this.id !== 'new') {
      this.$http(`${CONFIG}/cms/${this.id}`).then(res => {
        const item = res.data
        this.url = item[0].url
        this.name = item[0].name
        this.description = item[0].description
        this.seoTitle = item[0].seoTitle
        this.seoDescription = item[0].seoDescription
        this.seoKeywords = item[0].seoKeywords
        this.active = item[0].active
      })
    }
  },
  methods: {
    async save() {
      this.loading = true
      try {
        await this.$http({
          method: this.id ? 'put' : 'post',
          url: this.id ? `${CONFIG}/cms/${this.id}` : `${CONFIG}/cms`,
          data: {
            item: {
              name: this.name,
              url: this.url,
              description: this.description,
              seoTitle: this.seoTitle,
              seoDescription: this.seoDescription,
              seoKeywords: this.seoKeywords,
              active: this.active
            }
          }
        })
        this.loading = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    }
  }
}
</script>
